/*
These functions make sure WordPress
and Foundation play nice together.
*/

jQuery(document).ready(function() {

	// Remove empty P tags created by WP inside of Accordion and Orbit
	jQuery('.accordion p:empty, .orbit p:empty').remove();

	// Adds Flex Video to YouTube and Vimeo Embeds
	jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
		if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
		  jQuery(this).wrap("<div class='widescreen responsive-embed'/>");
		} else {
		  jQuery(this).wrap("<div class='responsive-embed'/>");
		}
	});

	// slick carousel settings
	var slickInitialized = false;

	jQuery().ready(function(){
		slickReinit();
	});

	// Debounce-function:
	function debounce(func, wait, immediate) {
		var timeout;
		return function() {
			var context = this, args = arguments;
			var later = function() {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

	var slickReinit = debounce(function() {
		if (!slickInitialized && jQuery(window).width() > 640) {
			slickSettings = {
				arrows: true,
				dots: true,
				adaptiveHeight: true,
				responsive: [{
					breakpoint: 640,
					settings: "unslick"
				}]
			};
			jQuery('.slick-post-carousel').slick(slickSettings);
			slickInitialized = true;
			jQuery('.slick-post-carousel').on('destroy', function() {
				slickInitialized = false;
			});
			jQuery(window).off('resize.slickReinit');
		}
		jQuery(window).on('resize.slickReinit', slickReinit);
	}, 250);

	jQuery(window).resize(function() {
		if (jQuery(window).width() < 1024) {
			if (jQuery( "#desktop-nav-container" ).hasClass( "expanded" )){
				jQuery('#desktop-nav-container').foundation('toggle');
			}
		} else {
			jQuery('#off-canvas').foundation('close');
		}
	});

	jQuery(document).mouseup(function (e){
		if (jQuery( "#desktop-nav-container" ).hasClass( "expanded" )){
			jQuery('#desktop-nav-container').foundation('toggle');
		}
	});

	var modalContactButton = jQuery('a[href="#contact-reveal"]');

	if (jQuery(modalContactButton).length){

		//contactModal
		modalContactButton.removeAttr("href");
		modalContactButton.attr( "data-open", "contactModal" );

	}

	// var = desktopNavToggeled = false;
	//
	// jQuery(document).on('on.zf.toggler', function(e){
	// 	desktopNavToggeled = true;
	// 	console.log(desktopNavToggeled);
	// }).on('off.zf.toggler', function(){
	// 	desktopNavToggeled = false;
	// 	console.log(desktopNavToggeled);
	// });

});
